import React, { useEffect } from 'react';

export default function Breadcrumb({ props, marginTop }) {
	const filteredList = props.filter((obj) => obj.hasOwnProperty('title'));

	const displayAlert = (e) => {
		e.preventDefault();
		window.confirm(
			`Redirecting from this page will stop the QC process. Captures already QC'd will be saved.\n Click OK to redirect.\n Click CANCEL to stay on this page.`
		);
		console.log(e.target.href);
	};

	

	return (
		<div style={{ marginTop: marginTop }}>
			<nav aria-label="breadcrumb">
				<ol className="breadcrumb">
					{filteredList.map(function (d, idx) {
						return (
							<>
								{/* current page */}
								{d.currentPage === true && (
									<li
										key={idx}
										className="breadcrumb-item active-bc fw-bold"
										aria-current="page"
									>
										<a>{d.title}</a>
									</li>
								)}

								{d.currentPage === false &&
									d.afterCurrent === false &&
									d.warning === true && (
										<li
											key={idx}
											cursor="pointer"
											className="breadcrumb-item  fw-lighter"
										>
											<a href={d.link} onClick={(e) => displayAlert(e)}>
												{d.title}
											</a>
										</li>
									)}

								{d.currentPage === false &&
									d.afterCurrent === true &&
									d.warning === false && (
										<li
											key={idx}
											cursor="not-allowed"
											className="breadcrumb-item fw-lighter"
										>
											<a aria-disabled>{d.title}</a>
										</li>
									)}

								{d.currentPage === false &&
									d.afterCurrent === true &&
									d.warning === true && (
										<li
											key={idx}
											cursor="not-allowed"
											className="breadcrumb-item fw-lighter"
										>
											<a aria-disabled>{d.title}</a>
										</li>
									)}

								{d.currentPage === false &&
									d.afterCurrent === false &&
									d.warning === false && (
										<li
											key={idx}
											cursor="not-allowed"
											className="breadcrumb-item fw-lighter"
										>
											<a href={d.link}>{d.title}</a>
										</li>
									)}
							</>
						);
					})}
				</ol>
			</nav>
		</div>
	);
}
