import React, { useEffect } from 'react';
import exportConfig from '../../config';
import Skeleton from 'react-loading-skeleton';

export const CurrentlyInQCAllUsers = ({ data, loading, error }) => {
	return (
		<div class="dashboard-card">
			<div
				class="dashboard-card-header"
				//data-tooltip-id="dashboard-system-status"
			>
				<div className="row">
					<h6 className="ms-2">Captures in QC</h6>
				</div>
			</div>
			<div class="dashboard-card-body">
				{loading ? (
					<Skeleton />
				) : data && data.data.length > 0 ? (
					<div className="table ">
						<table
							className="table dashboard-stats-table"
							style={{ width: '100%' }}
						>
							<thead>
								<tr>
									<th>User</th>
									<th className="text-center">Commodity</th>
									<th className="text-center">Caps in queue</th>
								</tr>
							</thead>
							<tbody>
								{data.data.map((user, idx) => (
									<tr key={idx}>
										<td>{user.user_id}</td>
										<td className="text-center">
											{
												exportConfig.commodities.filter(
													(comm) => comm.index === user.commodity_id
												)[0].id
											}
										</td>
										<td className="text-center">{user.count}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				) : (
					<h5>No captures currently in QC</h5>
				)}
			</div>
		</div>
	);
};
