import React from 'react';
import { Tooltip } from 'react-tooltip';

export const SiteChanges = () => {
	return (
		<>
			<div class="dashboard-card">
				<div
					class="dashboard-card-header"
					data-tooltip-id="dashboard-site-changes-tt"
				>
					<div className="row">
						<div className="col">
							<i className="fa-solid fa-location-pin me-2"></i> Site Changes
						</div>
						<div className="col d-flex justify-content-end"></div>
					</div>
				</div>
				<div class="dashboard-card-body">
					<table style={{ width: '100%' }}>
						<thead>
							<tr>
								<th>Site</th>
								<th>Previous State</th>
								<th>Current State</th>
								<th>Change date</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Jilin Xinda Iro...</td>
								<td>inactive</td>
								<td>active</td>
								<td>26/11/24</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<Tooltip id="dashboard-site-changes-tt" place="top" effect="solid">
				These sites have had a recent change in status<br></br> and a QC should
				be performed
			</Tooltip>
		</>
	);
};
