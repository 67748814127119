import React, { useContext, useEffect, useState } from 'react';
import divideReturnQCList from '../../helpers/divideReturnQCList';
import { endpointContext } from '../../contexts/endpointContext';
import { Modal } from 'bootstrap';
import config from '../../config';
import useGainsQCApi from '../../services/useGainsQCApi';

const SendCapsBackModal = ({ capturesCurrentlyInQC, user, qcCat }) => {
	const [sendingCapsBack, setSendingCapsBack] = useState(false);

	const { error: errorSendingCapsBack, makeRequest: sendCapsBack } =
		useGainsQCApi();

	const modalElement = document.getElementById('sendCapsBackModal');
	const endpointContextSendCapsBack = useContext(endpointContext);

	const functionSendCapsBack = () => {
		setSendingCapsBack(true);

		let cat = qcCat.toLowerCase();

		let commodity = capturesCurrentlyInQC[0].commodity_id;

		sendCapsBack(
			`${config.baseUrl}/${cat}/remove_from_queue`,
			'POST',
			{
				commodityIds: commodity.toString(),
				qcUser: `${user.givenName.toLowerCase()}.${user.familyName.toLowerCase()}`,
				captureIds: capturesCurrentlyInQC.map((cap) => cap.capture_id).join(',')
			}
			//`sending ${capturesCurrentlyInQC.length} captures back to database`
		);

		setSendingCapsBack(false);
	};

	console.log(user);

	return (
		<div
			className="modal modal-md fade"
			id="sendCapsBackModal"
			tabIndex="-1"
			aria-labelledby="sendCapsBackModalLabel"
			aria-hidden="true"
			style={{ marginTop: '100px' }}
		>
			<div className="modal-dialog">
				<div className="modal-content" style={{ marginLeft: '40px' }}>
					<div className="modal-header">
						<h5 className="modal-title" id="sendCapsBackModalLabel">
							Are you sure you want to remove {capturesCurrentlyInQC.length}{' '}
							captures from your pipeline?
						</h5>
						<button
							type="button"
							className="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"
						></button>
					</div>

					{sendingCapsBack && (
						<div className="modal-body">
							<h5>Sending captures back to database..</h5>
						</div>
					)}

					<div className="modal-footer">
						<button
							type="button"
							className="btn btn-secondary"
							data-bs-dismiss="modal"
						>
							Close
						</button>
						<button
							type="button"
							className="btn btn-danger"
							data-bs-dismiss="modal"
							onClick={(e) => functionSendCapsBack()}
						>
							Remove all captures
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SendCapsBackModal;
