import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

export const PerformedQCs = () => {

	const navigate = useNavigate();

	const navigateToQCReports = () => {
		//navigate('/qc_reports');
	}

	return (
		<div class="dashboard-card">
			<div class="dashboard-card-header">
			<div className="row">
						<div className="col-10">
							<i className="fa-regular fa-file-lines me-2"></i>QC Reports
						</div>
						<div className="col-2 d-flex justify-content-end">
							<i
								data-tooltip-id="currentlyQCingUser-navigate-to-qc-reports"
								id="captureImageHover"
								onClick={navigateToQCReports}
								className="fa-solid fa-arrow-up-right-from-square m-1"
								style={{color: 'rgb(130, 195, 89)'}}
							></i>
						</div>
					</div>
					
				
			</div>
			<div class="dashboard-card-body">
				<div className="table">
					<table className="table dashboard-stats-table my-0">
						<tr>
							<td>Last QC</td>
							<td className="fw-bold">45 minutes ago</td>
						</tr>

						<tr>
							<td>No. QC's this week</td>
							<td className="fw-bold">6</td>
						</tr>

						<tr>
							<td>No. QC's this month</td>
							<td className="fw-bold">29</td>
						</tr>
					</table>
				</div>

				{/* <div className="d-flex justify-content-center align-items-end mt-4">
					<button
						data-tooltip-id="dashboard-remove-caps"
						className="btn interactButton mx-2"
						style={{
							width: '180px',
							height: '40px',
							fontSize: '12px'
						}}
						//onClick={(e) => navigateToUsersCaptures()}
					>
						All QC Reports
					</button>
				</div> */}
			</div>

			<Tooltip
				place="top"
				effect="solid"
				id="currentlyQCingUser-navigate-to-qc-reports"
			>
				View all QC history and reports
			</Tooltip>
		</div>
	);
};
