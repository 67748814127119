import { Routes, Route, BrowserRouter } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import React from 'react';
import Dashboard from './components/Pages/Dashboard';
import Error from './components/Pages/Error';
import Login from './components/Pages/Login';
import Captures from './components/Pages/Captures';
import QCPage from './components/Pages/QCPage';
import Testing from './components/Pages/Testing';
import UsersCaptures from './components/Pages/UsersCaptures';
import AircraftPlot from './components/QC/Plots/AircraftPlot';
import { AircraftQC } from './components/Pages/AircraftQC';
import { Info } from './components/Pages/Info';
import { Users } from './components/Pages/Users';

const Router = ({ authProps }) => (
	<BrowserRouter>
		<Routes>
			<Route
				path="/"
				element={<ProtectedRoute authProps={authProps} component={Dashboard} />}
			/>

			<Route
				path="/aircraftqc"
				element={
					<ProtectedRoute authProps={authProps} component={AircraftQC} />
				}
			/>

			<Route
				path="/qc"
				element={<ProtectedRoute authProps={authProps} component={QCPage} />}
			/>

			<Route
				path="/info"
				element={<ProtectedRoute authProps={authProps} component={Info} />}
			/>

			<Route
				path="/capture"
				element={<ProtectedRoute authProps={authProps} component={Captures} />}
			/>

			<Route
				path="/captures"
				element={<ProtectedRoute authProps={authProps} component={Captures} />}
			/>

			<Route
				path="/testing"
				element={<ProtectedRoute authProps={authProps} component={Testing} />}
			/>

<Route
				path="/users"
				element={<ProtectedRoute authProps={authProps} component={Users} />}
			/>

			{/* <Route
				path="/removecaps"
				element={
					<ProtectedRoute
						
						authProps={authProps}
						component={RemoveCaps}
						
					/>
				}
			/> */}

			<Route
				path="/users_captures"
				element={
					<ProtectedRoute authProps={authProps} component={UsersCaptures} />
				}
			/>

			<Route
				path="/404"
				element={<ProtectedRoute authProps={authProps} component={Error} />}
			/>

			<Route
				path="*"
				element={<ProtectedRoute authProps={authProps} component={Error} />}
			/>

			<Route path="/login" element={<Login {...authProps} />} />

			<Route
				path="*"
				element={<ProtectedRoute authProps={authProps} component={Error} />}
			/>
		</Routes>
	</BrowserRouter>
);

export default Router;
