import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { API } from 'aws-amplify';

export const Users = (props) => {
	const [users, setUsers] = useState([]);

	useEffect(() => {
		const fetchUsers = async () => {
			try {
				const response = await API.get('gains-admin-api', '/users', {
					headers: {}
				});
				setUsers(response);
				return response;
			} catch (err) {
				console.log('Error getting users', err);
				return {};
			}
		};

		fetchUsers();
	}, []);

	return (
		<>
			{props.authProps.isAuthenticated ? (
				<div className="col px-0">
					<div className="contentContainer">
						<hr style={{ marginTop: '58px' }}></hr>
						<h2>QC Users</h2>
						<h5>Use this page to view the users allowed to perform QC</h5>

						<div className="my-4">
							<h5>Admin Users</h5>
							<div className="table">
								<table className="captureTable">
									<thead>
										<tr>
											<th>Name</th>
											<th>Email</th>
											<th>Role</th>
                                            <th>Organisation</th>
                                            <th>Can QC</th>
										</tr>
									</thead>

									<tbody>
										{users.length > 0 &&
											users
												.filter((user) => user.userRole === 'admin')
												.map((user, idx) => (
													<tr>
														<td>
															{user.givenName} {user.familyName}
														</td>
														<td>{user.email}</td>
														<td>{user.userRole}</td>
                                                        <td>{user.organisation}</td>
                                                        <td><i className="fa-regular fa-circle-check"></i></td>
													</tr>
												))}
									</tbody>
								</table>
							</div>
						</div>

						<div className="my-2">
							<h5>Super Users</h5>
							<div className="table">
								<table className="captureTable">
									<thead>
										<tr>
											<th>Name</th>
											<th>Email</th>
											<th>Role</th>
                                            <th>Organisation</th>
                                            <th>Can QC</th>
										</tr>
									</thead>

									<tbody>
										{users.length > 0 &&
											users
												.filter((user) => user.userRole === 'superUser')
												.map((user, idx) => (
													<tr>
														<td>
															{user.givenName} {user.familyName}
														</td>
														<td>{user.email}</td>
														<td>{user.userRole}</td>
                                                        <td>{user.organisation}</td>
                                                        <td><i className="fa-regular fa-circle-check"></i></td>
													</tr>
												))}
									</tbody>
								</table>
							</div>
						</div>

                        <div className="my-2">
							<h5>Super Users</h5>
							<div className="table">
								<table className="captureTable">
									<thead>
										<tr>
											<th>Name</th>
											<th>Email</th>
											<th>Role</th>
                                            <th>Organisation</th>
                                            <th>Can QC</th>
										</tr>
									</thead>

									<tbody>
										{users.length > 0 &&
											users
												.filter((user) => user.userRole === 'user')
												.map((user, idx) => (
													<tr>
														<td>
															{user.givenName} {user.familyName}
														</td>
														<td>{user.email}</td>
														<td>{user.userRole}</td>
                                                        <td>{user.organisation}</td>
                                                        <td><i className="fa-regular fa-circle-xmark"></i></td>
													</tr>
												))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			) : (
				<Navigate to="/login" />
			)}
		</>
	);
};
