import React, { useEffect, useContext, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { endpointContext } from '../../contexts/endpointContext';
import { pipelineContext } from '../../contexts/pipelineContext';
import QCActivityGraph from '../Dashboard/QCActivityGraph';
import exportConfig from '../../config';
import CurrentlyQCingUser from '../Dashboard/CurrentlyQCingUser';
import { DataLoaded } from '../Dashboard/DataLoaded';
import { AvailableCaps } from '../Dashboard/AvailableCaps';
import { PerformedQCs } from '../Dashboard/PerformedQCs';
import useGainsQCApi from '../../services/useGainsQCApi';
import { CurrentlyInQCAllUsers } from '../Dashboard/CurrentlyInQCAllUsers';
import navLogo from '../../images/gainsNav.png';
import { API } from 'aws-amplify';
import { DailyQCComplete } from '../Dashboard/DailyQCComplete';
import { QCTypePills } from '../Dashboard/QCTypePills';
import { SiteChanges } from '../Dashboard/SiteChanges';

export default function Dashboard(props) {
	const endpointContextDashboard = useContext(endpointContext);
	const pipelineContextDashboard = useContext(pipelineContext);

	const [removeCapsList, setRemoveCapsList] = useState();
	const [dashboardInfoAll, setDashboardInfoAll] = useState({
		smelters: {
			capsInQC: [
				{
					user: 'madiha.abbas',
					count: 20
				},
				{
					user: 'lucy.colley',
					count: 2
				}
			],
			lastQC: {
				count: 23,
				user: 'george.begkas',
				date: '13:24:12 26/11/24',
				type: 'daily'
			},
			userQC: {
				user: 'lucy.colley',
				toQC: 34,
				toUpload: 3
			},
			dailyQC: {
				preQCDate: '06:04 27/11/2024',
				QCDate: '11:18 27/11/2024',
				postQCDate: null,
				uploadDate: null,
				dataCheckDate: null
			}
		},
		stockpiles: {
			capsInQC: [
				{
					user: 'joshua.large',
					count: 14
				}
			],
			lastQC: {
				count: 23,
				user: 'joshua.large',
				date: '17:45:23 14/10/24',
				type: 'Historic'
			},
			userQC: {
				user: 'lucy.colley',
				toQC: 0,
				toUpload: 0
			},
			dailyQC: {
				preQCDate: null,
				QCDate: null,
				postQCDate: null,
				uploadDate: null,
				dataCheckDate: null
			}
		},
		airfields: {
			capsInQC: [],
			lastQC: {
				count: null,
				user: null,
				date: null,
				type: null
			},
			userQC: {
				user: 'lucy.colley',
				toQC: 0,
				toUpload: 0
			},
			dailyQC: {
				preQCDate: null,
				QCDate: null,
				postQCDate: null,
				uploadDate: null,
				dataCheckDate: null
			}
		}
	});
	const [dashboardInfo, setDashboardInfo] = useState(null);
	const [dashboardQCType, setDashboardQCType] = useState('smelters');

	const removeCapsFromQueue = useGainsQCApi();
	const capsInQueue = useGainsQCApi();
	const [users, setUsers] = useState();

	useEffect(() => {
		pipelineContextDashboard.setNavigateHome(false);
		setDashboardInfo(dashboardInfoAll['smelters']);
		document.title = 'GAINS QC | Home';
	}, []);

	useEffect(() => {
		const fetchCapsInQueue = async () => {
			await capsInQueue.makeRequest(
				`${exportConfig.baseUrl}/smelters/queue_length`,
				'GET',
				null
			);
		};

		fetchCapsInQueue();
	}, []);

	useEffect(() => {
		setDashboardInfo(dashboardInfoAll[dashboardQCType]);
	}, [dashboardQCType]);

	const getAllUsers = useGainsQCApi();

	useEffect(() => {
		const fetchUsers = async () => {
			console.log('here');
			try {
				const response = await API.get('gains-admin-api', '/users', {
					headers: {}
				});
				setUsers(response.filter((user) => user.userRole === 'admin').length);
				return response;
			} catch (err) {
				console.log('Error getting users', err);
				return {};
			}
		};

		fetchUsers();
	}, []);

	console.log(getAllUsers);

	const sendCapsBack = async () => {
		await removeCapsFromQueue.makeRequest(
			`${exportConfig.baseUrl}/smelters/remove_from_queue`,
			'POST',
			{
				commodityIds: '3',
				qcUser: 'lucy.colley',
				captureIds: removeCapsList
			},
			`sending ${removeCapsList.length} captures back to database`
		);
	};

	let queueData = {
		message: 'Success',
		data: [
			{
				user_id: 'george.begkas',
				commodity_id: 3,
				count: 5
			},
			{
				user_id: 'lucy.colley',
				commodity_id: 3,
				count: 18
			}
		]
	};

	const sumNumbers = (obj) => {
		let sum = 0;
		for (let key in obj) {
			if (typeof obj[key] === 'number') {
				sum += obj[key];
			} else if (typeof obj[key] === 'object' && obj[key] !== null) {
				sum += sumNumbers(obj[key]); // Recursively sum nested objects
			}
		}
		return sum;
	};

	console.log(dashboardInfo);

	return (
		<>
			{props.authProps.isAuthenticated ? (
				<>
					<div className="px-0 col" id="secondColumn">
						<div className="contentContainer">
							<div style={{ marginTop: '58px' }}>
								<hr className="container-hr"></hr>

								{/* <SmelterInfoModal 
								data={makeRequestSmelters.data}
								 /> */}

								<div className="row">
									<div className="col col-6">
										<h3>
											Welcome to QC{' '}
											<strong>
												{props.authProps.userAttributes.givenName}
											</strong>
										</h3>
									</div>
									<div className="col col-6 d-flex justify-content-end">
										{' '}
										<QCTypePills
											dashboardQCType={dashboardQCType}
											setDashboardQCType={setDashboardQCType}
										/>
									</div>
								</div>

								<div className="row">
									<div className="col col-md-6">
										<h6>
											Welcome to the Earth-i QC Tool, where you can find
											information about all captures in the database and perform
											a Quality Control inspection for a given set of captures.{' '}
										</h6>
									</div>

									<div className="col col-md-6"></div>
								</div>

								<div className="row">
									<div className="col-3"></div>
								</div>

								{/* 
								<div className="row">
									<div className="col-3">
										{' '}
										<input
											type="text"
											value={removeCapsList}
											onChange={(e) => setRemoveCapsList(e.target.value)}
										></input>
										<button
											className="btn btn-secondary"
											onClick={(e) => sendCapsBack()}
										>
											Remove from queue
										</button>
									</div>
								</div> */}

								<div className="row">
									<h4 className="mt-4 mb-2">
										{
											<i
												className={
													exportConfig.siteTypesIds.filter(
														(type) => type.name === dashboardQCType
													)[0].icon
												}
											></i>
										}{' '}
										{dashboardQCType.charAt(0).toUpperCase() +
											dashboardQCType.slice(1)}
									</h4>
									<div className="col-8">
										<div className="row my-2" style={{ height: 'auto' }}>
											<div className="col-12 col-md-6 col-xl-4">
												<div class="dashboard-card">
													<div
														class="dashboard-card-header"
														data-tooltip-id="dashboard-site-changes-tt"
													>
														<div className="row">
															<div className="col d-flex align-items-center">
																<div className="flashing-dot me-3"></div>
																Captures in QC
															</div>
														</div>
													</div>
													<div class="dashboard-card-body">
														{dashboardInfo &&
														dashboardInfo.hasOwnProperty('capsInQC') &&
														dashboardInfo.capsInQC.length === 0 ? (
															<p>There are no captures in QC</p>
														) : (
															<table style={{ width: '100%' }}>
																<tbody>
																	{dashboardInfo &&
																		dashboardInfo.hasOwnProperty('capsInQC') &&
																		dashboardInfo.capsInQC.map((item, idx) => (
																			<tr>
																				<td>{item.user}</td>
																				<td className="text-end">
																					{item.count}
																				</td>
																			</tr>
																		))}
																</tbody>
															</table>
														)}
													</div>
												</div>
											</div>

											<div className="col-12 col-md-6 col-xl-4">
												<div class="dashboard-card">
													<div
														class="dashboard-card-header"
														data-tooltip-id="dashboard-site-changes-tt"
													>
														<div className="row">
															<div className="col d-flex align-items-center">
																<i class="fa-solid fa-clock me-3"></i>
																Last QC
															</div>
														</div>
													</div>
													<div class="dashboard-card-body">
														{dashboardInfo &&
															dashboardInfo.hasOwnProperty('lastQC') && (
																<table style={{ width: '100%' }}>
																	<tbody>
																		<tr>
																			<td>Date</td>
																			<td className="text-center fw-bold">14 hours ago</td>
																		</tr>

																		<tr>
																			<td>QC Type</td>
																			<td className="text-center fw-bold">{dashboardInfo['lastQC'].type}</td>
																		</tr>

																		<tr>
																			<td>User</td>
																			<td className="text-center fw-bold">{dashboardInfo['lastQC'].user}</td>
																		</tr>

																		<tr>
																			<td>Captures QC'd</td>
																			<td className="text-center fw-bold">{dashboardInfo['lastQC'].count}</td>
																		</tr>
																	</tbody>
																</table>
															)}
													</div>
												</div>
											</div>

											<div className="col-12 col-md-6 col-xl-4"></div>
										</div>

										<div className="row my-4" style={{ height: 'auto' }}>
											<div className="col-8">
												{' '}
												<SiteChanges />
											</div>
										</div>

										<div className="row my-4" style={{ height: 'auto' }}>
											<div className="col-12">
												<div className="dashboard-div p-3">
													<h6 className="fw-bold">Number of captures QC'd</h6>
													<QCActivityGraph />
												</div>
											</div>
										</div>
									</div>
									<div className="col-4">
										<div className="row my-4" style={{ height: 'auto' }}>
											<div className="col-12">
												<DailyQCComplete dashboardInfo={dashboardInfo} />
											</div>
										</div>

										<div className="row my-4" style={{ height: 'auto' }}>
											<div className="col-12">
												<CurrentlyInQCAllUsers
													data={capsInQueue.data}
													loading={capsInQueue.loading}
													error={capsInQueue.error}
												/>
											</div>
										</div>

										<div className="row my-4" style={{ height: 'auto' }}>
											<div className="col-12">
												<DataLoaded
													loading={endpointContextDashboard.loading}
													error={endpointContextDashboard.error}
													data={endpointContextDashboard.dbData}
													timeNow={endpointContextDashboard.dbData.lastUpdate}
													userCount={users}
												/>
											</div>
										</div>

										<div className="row my-4" style={{ height: 'auto' }}>
											<div className="col-12">
												<div class="dashboard-card">
													<div
														class="dashboard-card-header"
														data-tooltip-id="dashboard-system-status"
													>
														<div className="row">
															<div className="col">
																<i className="fa-solid fa-link me-2"></i> Useful
																links
															</div>
															<div className="col d-flex justify-content-end"></div>
														</div>
													</div>
													<div class="dashboard-card-body">
														<div className="table my-0 py-0">
															<table className="table dashboard-stats-table my-0">
																<tr>
																	<td>
																		<img src={{ navLogo }}></img>
																	</td>
																	<td>Gains Navigate</td>
																</tr>

																<tr>
																	<td></td>
																	<td>Gains Admin Tool</td>
																</tr>

																<tr>
																	<td></td>
																	<td>Savant QC</td>
																</tr>

																<tr>
																	<td></td>
																	<td>Sentinel Hub</td>
																</tr>
															</table>
														</div>
													</div>
												</div>
											</div>
										</div>

										<div className="row my-4" style={{ height: 'auto' }}>
											<div className="col-12">
												<PerformedQCs />
											</div>
										</div>

										<div className="row my-4" style={{ height: 'auto' }}>
											<div className="col-12">
												<CurrentlyQCingUser
													user={props.authProps.userAttributes}
													loading={capsInQueue.loading}
													error={capsInQueue.error}
													data={capsInQueue.data}
												/>
											</div>
										</div>

										<div className="row my-4" style={{ height: '45vh' }}>
											<div className="col-12">
												<div className="dashboard-div p-3">
													<h6 className="fw-bold">
														Recent Activity
														<span className="fw-light"> | today</span>
													</h6>

													<div className="table">
														<table className="table dashboard-stats-table">
															<tr>
																<td>32 mins</td>
																<td className="truncate">
																	Madiha uploaded 46 smelter captures
																</td>
															</tr>

															<tr>
																<td>2 hours</td>
																<td className="truncate">
																	Madiha QC'd 46 smelter captures
																</td>
															</tr>

															<tr>
																<td>4 hours</td>
																<td className="truncate">
																	George QC'd 22 stockpiles captures
																</td>
															</tr>

															<tr>
																<td>1 day</td>
																<td className="truncate">
																	Peter QC'd 134 smelter captures
																</td>
															</tr>

															<tr>
																<td>1 day</td>
																<td className="truncate">
																	Josh uploaded 78 stockpiles captures
																</td>
															</tr>

															<tr>
																<td>1 day</td>
																<td className="truncate">
																	Josh QC'd 78 stockpiles captures
																</td>
															</tr>

															<tr>
																<td>2 days</td>
																<td className="truncate">
																	Guy requested Re-QC for 4 smelter captures
																</td>
															</tr>
														</table>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			) : (
				<Navigate to="/login" />
			)}
		</>
	);
}
